import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import imgNewList from 'img/blog/updates/new_session_list.png';
import imgNewPlayer from 'img/blog/updates/new_player.png';
import imgPreview from 'img/blog/updates/new_session_list_preview.png';
import videoPreview from 'img/livesessionApp/webapp_preview.png';
import dashboardTraffic from 'img/blog/updates/dashboard_traffic.png';
import dashboardTechnology from 'img/blog/updates/dashboard_technology.png';

import video from 'video/webapp.webm';
import video2 from 'video/webapp.mp4';

const Content = () => {
  return (
    <div>
      <p>
        We&apos;ve made a couple of important changes this week. First of all,{' '}
        <strong>the session list view and player have been split</strong>. From now on, it&apos;s
        not the same screen. Why did we change this? Because it was hard to extend, there was no
        place for new features, and it started to be cluttered. You can find a preview of the new
        session list below:
      </p>

      <ImageModal
        imgStyle={{ margin: '2rem 0' }}
        src={imgNewList}
        alt="LiveSession player"
        title="New session list"
      />

      <p>
        The new layout has pre-defined segments, so{' '}
        <strong>you can apply ready-to-go filters with one click</strong>. There&apos;s a normal
        pagination - infinite scroll was cool, but also very problematic. You can see applied
        filters, which gives you an overview of the current state. Finally,{' '}
        <strong>
          when you click on specific session attribute you search session replays with this specific
          property
        </strong>
        .
      </p>
      <p className="mt-3 mb-0">That&apos;s how the new player looks like:</p>

      <ImageModal
        imgStyle={{ margin: '2rem 0' }}
        src={imgNewPlayer}
        alt="LiveSession player"
        lazy
        title="New LiveSession player"
      />

      <p className="mb-0">
        Next change on the list is the dashboard. You can use it to get insights about your
        visitors. It consists of two sections right now: trafic sources and technology. The first
        one lets you referrers, UTM parameters, and countries of your visitors.{' '}
      </p>

      <ImageModal
        imgStyle={{ margin: '2rem 0' }}
        src={dashboardTraffic}
        alt="LiveSession player"
        lazy
        title="List in dashboard"
      />

      <p className="mb-0">
        The second one tells you about screen resolutions, top browsers, and top devices used by
        your visitors.
      </p>

      <ImageModal
        imgStyle={{ margin: '2rem 0' }}
        src={dashboardTechnology}
        alt="LiveSession player"
        lazy
        title="List in dashboard - technology"
      />

      <p className="mb-0">Check how it works on video: </p>

      <video
        style={{ margin: '2rem 0' }}
        poster={videoPreview}
        width="100%"
        height="100%"
        muted
        controls
      >
        <source src={video2} type="video/webm" />
        <source src={video} type="video/mp4" />
      </video>

      <p className="mt-4">Thanks for your time! Please leave feedback below. </p>
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'New session list and player layout',
  url: '/blog/session-player-layout-update/',
  description: "We've changed the layout of the session list and the player",
  author,
  img: imgPreview,
  date: '2019-11-05',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
